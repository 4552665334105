"use client";

import React from "react";
import Link from "next/link";
import { Button, ButtonProps } from "@/components/ui/button";
import { track } from "@vercel/analytics/react";
import { cn } from "@/lib/utils";

export default function SignupButton({
  variant = "default",
  href,
  className,
  size = "default",
  children,
}: {
  variant?: ButtonProps["variant"];
  href?: string;
  className?: string;
  size?: ButtonProps["size"];
  children?: React.ReactNode;
}) {
  const handleClick = () => {
    track("signup_button_clicked", { location: "header" });
  };

  return (
    <Link href={href || "/signup"}>
      <Button
        variant={variant}
        className={cn("shrink-0", className)}
        onClick={handleClick}
        size={size}
      >
        {children || "Create account"}
      </Button>
    </Link>
  );
}
