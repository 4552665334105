"use client";

import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import { Button } from "@/components/ui/button";
import { cn } from "@/lib/utils";
import { cva, VariantProps } from "class-variance-authority";
import Link, { LinkProps } from "next/link";
import React, { forwardRef, useState } from "react";

const linkVariants = cva(
  "tracking-normal text-zinc-500 underline-offset-4 transition-colors duration-200 ease-in-out hover:text-zinc-600 dark:text-zinc-300 dark:hover:text-zinc-200",
  {
    variants: {
      underline: {
        always: "underline",
        hover: "hover:underline",
      },
    },
    defaultVariants: {
      underline: "always",
    },
  },
);

type StyledLinkProps = LinkProps &
  VariantProps<typeof linkVariants> & {
    className?: string;
    href?: string;
    target?: string;
    children: React.ReactNode;
    rel?: string;
  };

const StyledLink = forwardRef<HTMLAnchorElement, StyledLinkProps>(
  function StyledLink(
    { href, target, children, underline, className, rel, ...props },
    ref,
  ) {
    return (
      <Link
        className={cn(
          linkVariants({
            underline,
          }),
          className,
        )}
        href={href}
        target={target}
        rel={rel}
        ref={ref}
        {...props}
      >
        {children}
      </Link>
    );
  },
);

export default StyledLink;

type UnsafeLinkWarningProps = {
  href: string;
  className?: string;
  children: React.ReactNode;
};

export function UnsafeLinkWarning({
  href,
  className,
  children,
}: UnsafeLinkWarningProps) {
  const [open, setOpen] = useState(false);

  if (!href || !href.startsWith("https://")) {
    return children;
  }

  return (
    <AlertDialog open={open} onOpenChange={setOpen}>
      <AlertDialogTrigger asChild>
        <StyledLink
          // @ts-ignore
          href={href}
          target="_blank"
          rel="noopener noreferrer nofollow"
          className={cn(
            "cursor-pointer font-mono text-sm text-blue-500 underline hover:text-blue-600",
            className,
          )}
          onClick={(e) => {
            e.preventDefault();
            setOpen(true);
          }}
        >
          {children}
        </StyledLink>
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>External Link</AlertDialogTitle>
          <AlertDialogDescription>
            You are about to leave Bugpilot and visit an external link. External
            links may be unsafe. Please inspect the URL before proceeding. Click
            the link below to continue.
          </AlertDialogDescription>
        </AlertDialogHeader>

        <StyledLink
          // @ts-ignore
          href={href}
          target="_blank"
          rel="noopener noreferrer nofollow"
          className="cursor-pointer font-mono text-sm text-blue-500 underline hover:text-blue-600"
        >
          {href}
        </StyledLink>

        <AlertDialogFooter className="mt-4">
          <AlertDialogCancel asChild>
            <Button variant="outline" size="sm">
              Close
            </Button>
          </AlertDialogCancel>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}
