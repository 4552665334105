"use client";

import * as React from "react";
import { Slot } from "@radix-ui/react-slot";
import { cva, type VariantProps } from "class-variance-authority";

import { cn } from "@/lib/utils";
import {
  Tooltip,
  TooltipArrow,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";

const buttonVariants = cva(
  "inline-flex items-center justify-center rounded-md text-sm transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-zinc-950 disabled:pointer-events-none disabled:opacity-50 dark:focus-visible:ring-zinc-300",
  {
    variants: {
      variant: {
        default:
          "bg-zinc-900 text-zinc-50 shadow hover:bg-zinc-900/90 dark:bg-zinc-50 dark:text-zinc-900 dark:hover:bg-zinc-50/90",
        destructive:
          "bg-red-500 text-zinc-50 shadow-sm hover:bg-red-500/90 dark:bg-red-900 dark:text-zinc-50 dark:hover:bg-red-900/90",
        outline:
          "border border-zinc-200 bg-white shadow-sm hover:bg-zinc-100 hover:text-zinc-900 dark:border-zinc-800 dark:bg-zinc-950 dark:hover:bg-zinc-800 dark:hover:text-zinc-50",
        secondary:
          "bg-zinc-100 text-zinc-900 shadow-sm hover:bg-zinc-100/80 dark:bg-zinc-800 dark:text-zinc-50 dark:hover:bg-zinc-800/80",
        ghost:
          "text-zinc-950 hover:text-zinc-800 dark:text-zinc-50 dark:hover:text-zinc-200",
        link: "text-zinc-900 underline-offset-4 hover:underline dark:text-zinc-50",
      },
      size: {
        default: "h-9 px-4 py-2",
        xs: "h-6 rounded-md px-2 text-xs",
        sm: "h-8 rounded-md px-3 text-sm",
        lg: "h-10 rounded-md px-8 text-[1.0rem]",
        icon: "h-9 w-9",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  },
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
  loading?: boolean;
  onClick?: (...args: any[]) => void | Promise<any>;
  tooltipText?: string;
  tooltipSide?: "top" | "bottom" | "left" | "right";
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      variant,
      size,
      asChild = false,
      loading: loadingProp,
      tooltipText,
      tooltipSide,
      ...props
    },
    ref,
  ) => {
    const Comp = asChild ? Slot : "button";
    const [loading, setLoading] = React.useState(false);

    const onClickWithLoading = (...args: any[]) => {
      // if the onClick prop is a promise, use a loading state
      const result = props.onClick?.(...args);
      if (result instanceof Promise) {
        setLoading(true);
        result.finally(() => setLoading(false));
      }
    };

    const comp = (
      <Comp
        className={cn(buttonVariants({ variant, size, className }), {
          "cursor-wait opacity-40": loadingProp || loading,
        })}
        ref={ref}
        {...props}
        onClick={onClickWithLoading}
        disabled={props.disabled || loading}
      />
    );

    if (tooltipText) {
      return (
        <TooltipProvider>
          <Tooltip disableHoverableContent delayDuration={1000}>
            <TooltipTrigger asChild>{comp}</TooltipTrigger>
            <TooltipContent
              side={tooltipSide}
              sideOffset={10}
              className="max-w-prose text-wrap px-3 py-1.5 font-sans text-sm"
            >
              <span>{tooltipText}</span>
              <TooltipArrow />
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      );
    }

    return comp;
  },
);
Button.displayName = "Button";

export { Button, buttonVariants };
