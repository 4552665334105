import { ResourcesConfig } from "aws-amplify";

const origin =
  typeof window !== "undefined"
    ? window.location.origin
    : "https://app.bugpilot.io";

export const config: ResourcesConfig = {
  Auth: {
    Cognito: {
      userPoolId: "eu-west-1_atjkI9Ixz",
      userPoolClientId: "51rlr8fejke8hisv9b8d2hgm3f",
      identityPoolId: "eu-west-1:9ee32c1d-454c-48d9-8f8c-1e7ec733332f",
      signUpVerificationMethod: "code",
      loginWith: {
        oauth: {
          domain: "auth.bugpilot.io",
          scopes: [
            "aws.cognito.signin.user.admin",
            "email",
            "profile",
            "openid",
          ],
          redirectSignIn: [new URL("/login-callback", origin).href],
          redirectSignOut: [new URL("/login", origin).href],
          responseType: "code", // or 'token', note that REFRESH token will only be generated when the responseType is code
        },
      },
    },
  },
};
