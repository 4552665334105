import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";

import { createServerRunner } from "@aws-amplify/adapter-nextjs";
import { config } from "@/amplify-config";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const { runWithAmplifyServerContext } = createServerRunner({
  config,
});

export function stopPropagation(fn: () => void) {
  return (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    fn();
  };
}

export const isDarkMode = () =>
  typeof window !== "undefined" &&
  window.matchMedia("(prefers-color-scheme: dark)").matches;
